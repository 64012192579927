import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    const openLogin = () => setShowLogin(true);
    const closeLogin = () => setShowLogin(false);
    const openSignUp = () => setShowSignUp(true);
    const closeSignUp = () => setShowSignUp(false);

    return (
        <ModalContext.Provider
            value={{ showLogin, openLogin, closeLogin, showSignUp, openSignUp, closeSignUp }}
        >
            {children}
        </ModalContext.Provider>
    );
};
