import React from "react";
import "./Privacy.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Privacy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="This page outlines RezBuddy's privacy policy." />
                <link rel="canonical" href="/privacy-policy" />
            </Helmet>
            <main className="privacy main">
                <h1 className="privacy__header"> Privacy Policy</h1>
                <p className="privacy__body">
                    RezBuddy ("we," "us," or "our") operates the RezBuddy website ("Site") and
                    mobile application ("App"), which provide users with information on First
                    Nation-owned gas stations and businesses across Canada. This Privacy Policy
                    explains how we collect, use, disclose, and safeguard your information when you
                    visit our Site and use our App. By accessing or using our services, you agree to
                    the terms of this Privacy Policy.
                </p>

                <h2 className="privacy__subhead">1. Information We Collect</h2>

                <h3 className="privacy__subheadxs">1.1 Personal Information</h3>
                <p className="privacy__body">
                    We may collect personal information that you voluntarily provide to us when you
                    register on the Site, sign up for our newsletter, fill out a contact form, or
                    use our services. This information may include, but is not limited to:
                </p>
                <ul className="privacy__list">
                    <li className="privacy__item">Name</li>
                    <li className="privacy__item">Email address</li>
                    <li className="privacy__item">Phone number</li>
                    <li className="privacy__item">Mailing address (optional)</li>
                    <li className="privacy__item">Indian Status card information (optional)</li>
                </ul>

                <h3 className="privacy__subheadxs">1.2 Non-Personal Information</h3>
                <p className="privacy__body">
                    We automatically collect certain non-personal information when you visit our
                    Site or use our App. This information may include:
                </p>
                <ul className="privacy__list">
                    <li className="privacy__item">
                        Device information (e.g., IP address, browser type, operating system)
                    </li>
                    <li className="privacy__item">
                        Usage data (e.g., pages visited, time spent on the Site, search queries)
                    </li>
                </ul>

                <h3 className="privacy__subheadxs">1.3 Location Information</h3>
                <p className="privacy__body">
                    With your consent, we may collect precise geolocation data to help you find
                    nearby First Nation gas stations and businesses through google maps.
                </p>

                <h2 className="privacy__subhead">2. How We Use Your Information</h2>
                <p className="privacy__body">
                    We use the information we collect for various purposes, including:
                </p>
                <ul className="privacy__list">
                    <li className="privacy__item">
                        To provide, operate, and maintain our services
                    </li>
                    <li className="privacy__item">
                        To improve, personalize, and expand our services
                    </li>
                    <li className="privacy__item">
                        To communicate with you, including responding to your inquiries and
                        providing customer support
                    </li>
                    <li className="privacy__item">
                        To send you updates, newsletters, and promotional materials
                    </li>
                    <li className="privacy__item">
                        To process transactions and manage your account
                    </li>
                    <li className="privacy__item">
                        To monitor and analyze usage and trends to improve user experience
                    </li>
                    <li className="privacy__item">
                        To protect against fraudulent or unauthorized activities
                    </li>
                    <li className="privacy__item">
                        To comply with legal obligations and enforce our terms and policies
                    </li>
                </ul>

                <h2 className="privacy__subhead">3. How We Share Your Information</h2>
                <p className="privacy__body">
                    We do not sell, trade, or otherwise transfer your personal information to
                    outside parties except as described in this Privacy Policy. We may share your
                    information in the following circumstances:
                </p>

                <h3 className="privacy__subheadxs">3.1 Service Providers</h3>
                <p className="privacy__body">
                    We may share your information with third-party service providers who perform
                    services on our behalf, such as hosting, data analysis, payment processing, and
                    customer support.
                </p>

                <h3 className="privacy__subheadxs">3.2 Legal Requirements</h3>
                <p className="privacy__body">
                    We may disclose your information if required by law or in response to valid
                    requests by public authorities (e.g., a court or government agency).
                </p>

                <h3 className="privacy__subheadxs">3.3 Business Transfers</h3>
                <p className="privacy__body">
                    In the event of a merger, acquisition, or sale of all or a portion of our
                    assets, your information may be transferred to the new owner.
                </p>

                <h2 className="privacy__subhead">4. Data Security</h2>
                <p className="privacy__body">
                    We implement a variety of security measures to protect your personal information
                    from unauthorized access, use, or disclosure. However, no method of transmission
                    over the Internet or method of electronic storage is 100% secure. Therefore, we
                    cannot guarantee absolute security.
                </p>

                <h2 className="privacy__subhead">5. Your Rights</h2>
                <p className="privacy__body">
                    You have certain rights regarding your personal information, including:
                </p>
                <ul className="privacy__list">
                    <li className="privacy__item">
                        The right to access the personal information we hold about you
                    </li>
                    <li className="privacy__item">
                        The right to request the correction of inaccurate personal information
                    </li>
                    <li className="privacy__item">
                        The right to request the deletion of your personal information
                    </li>
                    <li className="privacy__item">
                        The right to object to or restrict the processing of your personal
                        information
                    </li>
                    <li className="privacy__item">
                        The right to withdraw your consent at any time
                    </li>
                </ul>
                <p className="privacy__body">
                    To exercise these rights, please contact us using the contact information
                    provided below.
                </p>

                <h2 className="privacy__subhead">6. Cookies and Tracking Technologies</h2>
                <p className="privacy__body">
                    Our Site uses cookies and similar tracking technologies to enhance your user
                    experience. You can control the use of cookies through your browser settings.
                </p>

                <h2 className="privacy__subhead">7. Third-Party Links</h2>
                <p className="privacy__body">
                    Our Site may contain links to third-party websites that are not operated by us.
                    We are not responsible for the privacy practices of these websites, and we
                    encourage you to review their privacy policies.
                </p>

                <h2 className="privacy__subhead">8. Children's Privacy</h2>
                <p className="privacy__body">
                    Our services are not intended for individuals under the age of 13. We do not
                    knowingly collect personal information from children under 13. If we become
                    aware that we have collected personal information from a child under 13, we will
                    take steps to delete such information.
                </p>

                <h2 className="privacy__subhead">9. Changes to This Privacy Policy</h2>
                <p className="privacy__body">
                    We may update this Privacy Policy from time to time. We will notify you of any
                    changes by posting the new Privacy Policy on this page and updating the "Last
                    Updated" date. You are advised to review this Privacy Policy periodically for
                    any changes.
                </p>

                <h2 className="privacy__subhead">10. Contact Us</h2>
                <p className="privacy__body">
                    If you have any questions about this Privacy Policy or our privacy practices,
                    please
                </p>
                <Link className="privacy__link" to="/contact-us">
                    <p className="privacy__link">Contact Us</p>
                </Link>
                <p className="privacy__body">
                    By using our Site and App, you acknowledge that you have read and understood
                    this Privacy Policy.
                </p>
                <p className="privacy__updated">Last Updated: June 19, 2024</p>
            </main>
        </>
    );
}

export default Privacy;
