import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./VerifyUser.scss";

function VerifyEmail() {
    const navigate = useNavigate();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    useEffect(() => {
        const verifyEmail = async () => {
            if (token) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/users/verify-email?token=${token}`
                    );
                    alert("Email verified successfully!");
                    navigate("/");
                } catch (error) {
                    const errorMessage = error.response
                        ? error.response.data
                        : "Failed to verify email. Please try again.";
                    alert(errorMessage);
                    navigate("/");
                }
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <main className="verify-container main">
            <h1>Thanks for signing up!</h1>
            <p>Please wait a moment while we authorize your account.</p>
            <p>If your account has already been confirmed, please sign in! </p>
            <p>You can also reset your password on the login menu. </p>
        </main>
    );
}

export default VerifyEmail;
