import React from "react";

function UpdateAddFormGroup({ id, label, type = "text", value, onChange, placeholder }) {
    return (
        <div className="form-group">
            <label htmlFor={id}>{label}</label>
            {type === "textarea" ? (
                <textarea
                    className="form__textarea"
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            ) : (
                <input
                    type={type}
                    className="form__input"
                    id={id}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            )}
        </div>
    );
}

export default UpdateAddFormGroup;
