import React, { useEffect, useState } from "react";
import "./Header.scss";
import Logo from "../../Assets/Icons/Native Gas Finder.svg";
import { useAuth } from "../LoginContext/LoginContext";
import Sidebar from "../Sidebar/Sidebar";
import { useModal } from "../ModalContext/ModalContext";

function Header() {
    const { isLoggedIn, logout } = useAuth();
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isHeaderHidden, setIsHeaderHidden] = useState(false);
    const { openLogin } = useModal();

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.scrollY || document.documentElement.scrollTop;
            if (currentScroll > lastScrollTop) {
                setIsHeaderHidden(true);
            } else {
                setIsHeaderHidden(false);
            }
            setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollTop]);

    return (
        <header className={`header ${isHeaderHidden ? "header-hidden" : ""}`}>
            <nav className="header-container">
                <div className="top-container">
                    {isLoggedIn ? (
                        <button className="top-container__left-menu" onClick={logout}>
                            Logout
                        </button>
                    ) : (
                        <button className="top-container__left-menu" onClick={openLogin}>
                            Login
                        </button>
                    )}
                    <a href="/">
                        <img
                            src={Logo}
                            alt="Native Gas Station Logo"
                            className="top-container__logo"
                        />
                    </a>
                    <div className="top-container__right-menu">
                        <Sidebar />
                    </div>
                </div>
                <a className="bottom-container" href="/">
                    <h1 className="bottom-container__header">RezBuddy</h1>
                </a>
            </nav>
        </header>
    );
}

export default Header;
