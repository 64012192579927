import React from "react";

function UpdateAddType({ addType, onAddTypeChange }) {
    return (
        <div className="add-type">
            <label className="add-type__label">
                <input
                    className="add-type__input"
                    type="radio"
                    value="indigenous_business"
                    checked={addType === "indigenous_business"}
                    onChange={onAddTypeChange}
                />
                Indigenous Business
            </label>
            <label className="add-type__label">
                <input
                    className="add-type__input"
                    type="radio"
                    value="gas_station"
                    checked={addType === "gas_station"}
                    onChange={onAddTypeChange}
                />
                Gas Station
            </label>
        </div>
    );
}

export default UpdateAddType;
