import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Details.scss";
import axios from "axios";
import { Helmet } from "react-helmet-async";

function Details() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchBusinessById(id);
        }
    }, [id]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const fetchBusinessById = async (id) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`http://localhost:8080/api/business/${id}`);
            setResults(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults(null);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!results) {
        return <p>No business details found.</p>;
    }

    return (
        <>
            <Helmet>
                <title>Indigenous Business Details</title>
                <meta
                    name="description"
                    content="Get details about first nation businesses, including phone number, name, website and a brief description."
                />
                <link rel="canonical" href="/indigenous-businesses/:id" />
            </Helmet>
            <main className="main">
                <button onClick={handleGoBack} className="business-details-back">
                    Back
                </button>
                <section key={results.id} className="business-card business-card--details">
                    <h3 className="business-card__name">Business Name:</h3>
                    <p className="business-card__body">{results.company_operating_name}</p>
                    <h3 className="business-card__location">Business Location:</h3>
                    <p className="business-card__body">
                        {results.city}, {results.province}
                    </p>
                    <h3 className="business-card__description">Business Description:</h3>
                    <p className="business-card__body">{results.company_description}</p>
                    <div>
                        {results.url ? (
                            <a href={results.url} target="_blank" rel="noopener noreferrer">
                                <p className="business-card__link">Website Link</p>
                            </a>
                        ) : (
                            <p className="business-card__error">No website available</p>
                        )}
                    </div>
                </section>
            </main>
        </>
    );
}

export default Details;
