import React from "react";
import "./About.scss";
import { Link } from "react-router-dom";

function About() {
    return (
        <section className="about-container">
            <div className="about-container__div-header">
                <h2 className="about-container__header">
                    Find Your Fuel: Affordable Gas on First Nation Lands
                </h2>
                <p className="about-container__body">
                    Welcome to <strong>RezBuddy</strong>, where saving on gas meets supporting First
                    Nation communities. Our platform is dedicated to helping status First Nation
                    members locate gas stations, where they can use their status card to get tax
                    free gas. We also want to help guide all Canadians towards affordable fuel
                    options and drive customers for indigenous businesses.
                </p>
            </div>
            <div className="about-container__div">
                <h3 className="about-container__subhead">
                    Why Choose <strong>RezBuddy</strong>?
                </h3>
                <div className="about-container__div-small">
                    <ul>
                        <li>
                            <h4 className="about-container__bullet-title">
                                Tax-Free Gas for First Nation Members
                            </h4>
                            <p className="about-container__body">
                                Present your Indian Status Card and enjoy tax exemptions on fuel
                                purchases at participating stations.
                            </p>
                        </li>
                        <li>
                            <h4 className="about-container__bullet-title">
                                Discover Affordable Fuel
                            </h4>
                            <p className="about-container__body">
                                Searching for "cheap gas near me"? Find the most competitive gas
                                prices across Canada with ease. Gas stations on a first nation
                                reserve will often have lower prices than gas stations off reserve,
                                even if you're not a status first nation member.
                            </p>
                        </li>
                        <li>
                            <h4 className="about-container__bullet-title">
                                Support Indigenous Businesses
                            </h4>
                            <p className="about-container__body">
                                Fuel up, find gifts, eat well and contribute to the prosperity of
                                First Nation communities. Your support, even it's brief, fosters
                                employment and business growth.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="about-container__div">
                <h3 className="about-container__subhead">Our Services</h3>
                <div className="about-container__div-small">
                    <h4 className="about-container__bullet-title">Quick Search:</h4>
                    <p className="about-container__body">
                        Our user-friendly search tool lets you instantly find answers to these types
                        of google searches:
                    </p>
                    <ul>
                        <li>- Gas stations near me</li>
                        <li>- Lowest gas prices near me</li>
                        <li>- Fuel stations near me with tax exemption for First Nation members</li>
                    </ul>
                </div>
                <div className="about-container__div-small">
                    <h4 className="about-container__bullet-title">Updated Gas Prices:</h4>
                    <p className="about-container__body">
                        We use a community system to update gas prices on reservation gas stations,
                        in an effort to show you the latest prices. Like GasBuddy, users can update
                        the current price of gas to allows others to see.
                        <br />
                        <strong>We Ask That You Please Participate</strong>
                    </p>
                </div>
                <div className="about-container__div-small">
                    <h4 className="about-container__bullet-title"> A Platform for All:</h4>
                    <p className="about-container__body">
                        Whether you're a status First Nation member seeking tax-free gas or a
                        traveler looking for the best fuel deals, RezBuddy is your go-to resource.
                        Not First Nation? No problem! Your patronage supports local communities and
                        businesses, making every dollar count towards positive impact (and gas
                        prices on reserves are generally cheaper than in town)
                    </p>
                </div>
            </div>
            <div className="about-container__div">
                <h3 className="about-container__subhead">
                    Supporting First Nation and Canadian Communities
                </h3>
                <div className="about-container__div-small">
                    <ul>
                        <li>
                            <p className="about-container__body">
                                Fueling at First Nation-operated gas stations drives job creation
                                and economic stability within indigenous communities.
                            </p>
                        </li>
                        <li>
                            <h4 className="about-container__bullet-title">
                                A Call to Action for Non-First Nation Canadians:
                            </h4>
                            <p className="about-container__body">
                                Join us in supporting First Nation businesses. Every visit, every
                                purchase, makes a difference. Miigwech.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="about-container__div">
                <p className="about-container__bullet-title">Disclaimer</p>
                <p className="about-container__sub-bod">
                    We strive to provide the most accurate and up-to-date information on First
                    Nation business locations and services across Canada, including gas stations and
                    fuel prices. Our data is gathered from a collaborative effort that includes the
                    Government of Canada’s First Nation Business Directory and contributions from
                    our valued users and community members. However, due to the dynamic nature of
                    business operations and fuel prices, there may be instances where the
                    information provided does not reflect the current situation. We encourage our
                    users to help enhance the accuracy and completeness of our data. If you
                    encounter any discrepancies, outdated information, or missing locations—whether
                    they are gas stations or other First Nation businesses—please do not hesitate to
                    <Link to="/contact-us" className="about-container__link">
                        contact us
                    </Link>
                    . Your feedback is invaluable in helping us make the RezBuddy is as reliable and
                    useful as possible.
                </p>
            </div>
        </section>
    );
}

export default About;
