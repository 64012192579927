import "./Homepage.scss";
import Search from "../../Components/Search/Search";
import Hero from "../../Components/Hero/Hero";
import PriceCards from "../../Components/PriceCards/PriceCards";
import React, { useState } from "react";
import Mapbox from "../../Components/Mapbox/Mapbox";
import { APIProvider } from "@vis.gl/react-google-maps";
import { useAuth } from "../../Components/LoginContext/LoginContext";
import BusinessSection from "../../Components/BusinessSection/BusinessSection";
import HomeAbout from "../../Components/HomeAbout/HomeAbout";

function Homepage() {
    const { isLoggedIn } = useAuth();
    const [showMapModal, setShowMapModal] = useState(false);

    return (
        <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            libraries={["places", "visualization"]}
        >
            <main className="main">
                <Search setShowMapModal={setShowMapModal} />
                <Hero />
                {!isLoggedIn && <div className="login-false">Login to save favourites here</div>}
                <PriceCards />
                <BusinessSection />
                <HomeAbout />
                {showMapModal && <Mapbox setShowMapModal={setShowMapModal} />}
            </main>
        </APIProvider>
    );
}

export default Homepage;
