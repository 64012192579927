import React from "react";

function UpdateAddSearch({
    searchTerm,
    onSearchTermChange,
    businessType,
    onBusinessTypeChange,
    results,
    onSelect,
    onExit,
    showDropdown,
    searchRef,
}) {
    return (
        <div className="search" ref={searchRef}>
            <input
                type="text"
                className="search__input"
                placeholder="Search..."
                value={searchTerm}
                onChange={onSearchTermChange}
            />
            <select className="search__select" onChange={onBusinessTypeChange} value={businessType}>
                <option value="indigenous_business">Indigenous Business</option>
                <option value="gas_station">Gas Station</option>
            </select>
            {showDropdown && results.length > 0 && (
                <ul className="search-dropdown">
                    {results.map((business) => (
                        <li
                            key={business.id}
                            className="search-dropdown__item"
                            onClick={() => onSelect(business)}
                        >
                            {business.company_operating_name || business.Name}
                        </li>
                    ))}
                    <li className="search-dropdown__item" onClick={onExit}>
                        Exit Search
                    </li>
                </ul>
            )}
        </div>
    );
}

export default UpdateAddSearch;
