import React from "react";
import "./Loading.scss";

function Loading() {
    return (
        <div className="spinner">
            <p className="spinner__text">🗺️🔍 Looking up your location...</p>
        </div>
    );
}

export default Loading;
