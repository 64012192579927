import React, { useEffect, useState } from "react";
import "./PinModal.scss";
import unfavourite from "../../Assets/Icons/unfavourite.svg";
import favourite from "../../Assets/Icons/favourite.svg";
import { useAuth } from "../LoginContext/LoginContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import ReportPrice from "../ReportPrice/ReportPrice";
import { useModal } from "../ModalContext/ModalContext";

function PinModal({ marker, exit }) {
    const { isLoggedIn, user } = useAuth();
    const [favouriteStatus, setFavouriteStatus] = useState({});
    const token = localStorage.getItem("token");
    const userId = token ? jwtDecode(token).userId : null;
    const [showReport, setShowReport] = useState(false);
    const { openLogin } = useModal();

    useEffect(() => {
        if (isLoggedIn && userId) {
            const fetchFavouriteStatus = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/favourites`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const favouriteStatusData = response.data.reduce((acc, station) => {
                        acc[station.ID] = true;
                        return acc;
                    }, {});
                    setFavouriteStatus(favouriteStatusData);
                } catch (error) {
                    console.error("Failed to fetch favourite status", error);
                }
            };
            fetchFavouriteStatus();
        }
    }, [isLoggedIn, userId, token]);

    const isFavourite = favouriteStatus[marker.ID] || false;

    const toggleFavourite = async () => {
        if (isLoggedIn) {
            try {
                const method = isFavourite ? "delete" : "post";
                await axios[method](
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/${user.id}/favourites/${marker.ID}`
                );

                setFavouriteStatus((prevStatus) => ({
                    ...prevStatus,
                    [marker.ID]: !prevStatus[marker.ID],
                }));
            } catch (error) {
                console.error("Failed to update favourite status", error.message);
            }
        } else {
            openLogin();
        }
    };

    const displayPhoneNumber = () => {
        if (marker.Phone && /\d{3}-\d{3}-\d{4}/.test(marker.Phone)) {
            return <a href={`tel:${marker.Phone}`}>{marker.Phone}</a>;
        } else {
            return "No phone number available";
        }
    };

    const displayPrice = marker.Price ? marker.Price : " n.a.";
    function convertTimestampToDate(timestamp) {
        const date = new Date(timestamp);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
    }
    const formattedDate = convertTimestampToDate(marker.updated_at);

    const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${marker.Location.Lat},${marker.Location.Long}`;

    return (
        <article className="pin-modal-container">
            <button
                onClick={exit}
                className="pin-modal-container__close-map"
                aria-label="Close map"
            >
                Close
            </button>
            <div className="pin-modal-container__buffer">
                <h3 className="pin-modal-container__name">{marker.Name}</h3>
                <p className="pin-modal-container__location">
                    {marker.Address}, {marker.City}, {marker.Province}
                </p>
                <p className="pin-modal-container__phone">Phone: </p>
                <p className="pin-modal-container__phone--colour"> {displayPhoneNumber()} </p>
                <p className="pin-modal-container__price">Last Reported Price: ${displayPrice}/L</p>
                <p className="pin-modal-container__updated-at">Updated on: {formattedDate}</p>
                <div className="pin-modal-container__directions">
                    <a href={directionsUrl} target="_blank" rel="noopener noreferrer">
                        Directions
                    </a>
                </div>

                <div className="favourite">
                    <button
                        className="favourite__header"
                        onClick={() => setShowReport((prevShowReport) => !prevShowReport)}
                    >
                        Report Price
                    </button>
                    <img
                        className="favourite__yes"
                        src={favouriteStatus[marker.ID] ? favourite : unfavourite}
                        alt={favouriteStatus[marker.ID] ? "favourited" : "not favourited"}
                        onClick={toggleFavourite}
                    />
                </div>
                {showReport && <ReportPrice exit={exit} marker={marker} />}
            </div>
        </article>
    );
}

export default PinModal;
