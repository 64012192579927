import React from "react";
import "./AlphabetList.scss";
import { Link } from "react-router-dom";

function AlphabetList({ currentLetter, handleCurrentLetter }) {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    return (
        <section className="bp-alph">
            <h2 className="bp-alph__header">Browse Alphabetically:</h2>
            <ul className="bp-alph__list">
                {alphabet.split("").map((letter, index) => (
                    <li key={index} className="bp-alph__item">
                        <button
                            className={
                                currentLetter === letter ? "bp-alph__item--active" : "bp-alph__item"
                            }
                            onClick={() => handleCurrentLetter(letter)}
                        >
                            {letter}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="bp-contact">
                <Link to="/contact-us" className="bp-contact__text">
                    Want to add/update a business?
                </Link>
            </div>
        </section>
    );
}

export default AlphabetList;
