import React from "react";
import "./NotFoundPage.scss";
import notFound from "../../Assets/Icons/404.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function NotFoundPage() {
    return (
        <main className="not-found main">
            <h3>404</h3>
            <h1>Page Not Found</h1>
            <img className="not-found__image" src={notFound} alt="404 page not found" />
            <div className="not-found__buttons">
                <Link to="/gas">
                    <button className="not-found__button">First Nation Gas Stations</button>
                </Link>
                <Link to="/">
                    <button className="not-found__button">Home</button>
                </Link>
                <Link to="/indigenous-businesses">
                    <button className="not-found__button"> Indigenous Business Directory</button>
                </Link>
            </div>
        </main>
    );
}

export default NotFoundPage;
