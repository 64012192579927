import React, { useRef, useState } from "react";
import "./Login.scss";
import userLogin from "../../Assets/Icons/user-login.svg";
import userAdd from "../../Assets/Icons/user-add.svg";
import lock from "../../Assets/Icons/lock.svg";
import email from "../../Assets/Icons/email.svg";
import loginIcon from "../../Assets/Icons/login.svg";
import SignUpForm from "../SignUpForm/SignUpForm";
import axios from "axios";
import { useAuth } from "../LoginContext/LoginContext";
import Forgot from "../Forgot/Forgot";
import { useModal } from "../ModalContext/ModalContext";
import useForm from "../Hooks/UseForm";
import FormField from "../Hooks/FormField";

function Login() {
    const initialState = {
        email: "",
        password: "",
    };

    const { values, errors, handleChange, handleSubmit, isSubmitting } = useForm(initialState);
    const passwordInputRef = useRef(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const { login } = useAuth();
    const { closeLogin, openSignUp } = useModal();
    const [forgotPassword, setForgotPassword] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const submitForm = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/login`,
                {
                    email: values.email,
                    password: values.password,
                }
            );

            const { token } = response.data;
            localStorage.setItem("token", token);
            login(token);
            setShowSuccessMessage(true);

            setTimeout(() => {
                setShowSuccessMessage(false);
                closeLogin();
            }, 3000);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.message : error.message;
            if (errorMessage === "Invalid credentials") {
                setLoginError("Invalid email or password.");
            }

            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
        }
    };

    const handleKeyDown = (e, inputName) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (inputName === "email") {
                passwordInputRef.current.focus();
            } else if (inputName === "password") {
                handleSubmit(e, submitForm);
            }
        }
    };

    return (
        <section className="login-overlay">
            {!forgotPassword ? (
                <>
                    <div className="login-container-top">
                        <div className="top-left">
                            <img className="top-left__img" src={userLogin} alt="Login" />
                            <p className="top-left__text">Login</p>
                        </div>
                        <div className="top-right" onClick={openSignUp}>
                            <img className="top-right__img" src={userAdd} alt="Sign Up" />
                            <p className="top-right__text">Sign Up</p>
                        </div>
                    </div>
                    <div className="login-container-bottom">
                        <form className="login-form" onSubmit={(e) => handleSubmit(e, submitForm)}>
                            <FormField
                                type="email"
                                name="email"
                                label="Email"
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Enter Your Email"
                                error={errors.email}
                                inputRef={null}
                                onKeyDown={(e) => handleKeyDown(e, "email")}
                                icon={email}
                                containerClass="login-form__field"
                                labelClass="login-form__label"
                                inputClass={`login-form__input--email ${
                                    (errors.email || loginError) && isSubmitting
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                                iconClass="login-form__img"
                            />
                            <FormField
                                type="password"
                                name="password"
                                label="Password"
                                value={values.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                error={errors.password}
                                inputRef={passwordInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "password")}
                                icon={lock}
                                containerClass="login-form__field"
                                labelClass="login-form__label"
                                inputClass={`login-form__input--password ${
                                    (errors.password || loginError) && isSubmitting
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                                iconClass="login-form__img"
                            />
                            <button
                                className="login-form__button"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Login
                                <img
                                    className="login-form__button--icon"
                                    src={loginIcon}
                                    alt="login icon"
                                />
                            </button>
                            {showSuccessMessage && (
                                <div
                                    className="login-success-message"
                                    onClick={() => setShowSuccessMessage(!showSuccessMessage)}
                                >
                                    Login Successful!
                                </div>
                            )}
                            <button
                                className="login-form__forgot"
                                onClick={() => setForgotPassword(true)}
                            >
                                Forgot Password?
                            </button>
                        </form>
                        <button className="login-form__close-button" onClick={closeLogin}>
                            Close
                        </button>
                    </div>
                </>
            ) : forgotPassword ? (
                <Forgot setForgotPassword={setForgotPassword} />
            ) : (
                <SignUpForm />
            )}
        </section>
    );
}

export default Login;
