import { useState } from "react";
import "./ResetPassword.scss";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const navigate = useNavigate();

    const [resetError, setResetError] = useState({});
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const validateForm = () => {
        let tempErrors = {};
        let formIsValid = true;

        if (password !== confirmPassword) {
            formIsValid = false;
            tempErrors["confirmPassword"] = "Passwords do not match";
        }

        if (password.length < 8) {
            formIsValid = false;
            tempErrors["password"] = "Password must be at least 8 characters long.";
        }

        if (!passwordRegex.test(password)) {
            formIsValid = false;
            tempErrors["password"] =
                "Password must contain a mix of uppercase, lowercase, numbers, and symbols.";
        }

        setResetError(tempErrors);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/reset-password`,
                    {
                        token: token,
                        password: password,
                        confirmPassword: confirmPassword,
                    }
                );
                setMessage(
                    "Password updated successfully! You can now log in with your new password."
                );
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } catch (error) {
                const errorMessage = error.response
                    ? error.response.data.message
                    : "Password update failed. Please try again later.";
                setMessage(errorMessage);
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            }
        }
    };

    return (
        <main className="reset-container main">
            <h1 className="reset-container__header">Reset Your Password</h1>
            <form className="reset-form" onSubmit={handleSubmit}>
                <div className="reset-form__input-container">
                    <label htmlFor="new password" className="reset-form__label">
                        New Password
                    </label>
                    <input
                        type="password"
                        placeholder="Enter New Password"
                        className={`reset-form__input ${resetError.password ? "input-error" : ""}`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="reset-form__input-container">
                    <label htmlFor="confirm new password" className="reset-form__label">
                        Confirm New Password
                    </label>
                    <input
                        type="password"
                        placeholder="Confirm New Password"
                        className={`reset-form__input ${resetError.password ? "input-error" : ""}`}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                {Object.values(resetError).map((error, index) => (
                    <div key={index} className="error-message">
                        {error}
                    </div>
                ))}
                <button className="reset-form__submit">Submit</button>
            </form>
            {message && <p className="error-message">{message}</p>}
        </main>
    );
}

export default ResetPassword;
