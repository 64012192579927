import React, { useState } from "react";
import "./Forgot.scss";
import axios from "axios";

function Forgot({ setForgotPassword }) {
    const [forgottenEmail, setForgottenEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/forgot-password`, {
                email: forgottenEmail,
            });
            setMessage(
                "Password reset link sent! Please check your email to change the password on your account."
            );
            setTimeout(() => {
                setForgotPassword(false);
            }, 2000);
        } catch (error) {
            const errorMessage =
                error.response && error.response.data
                    ? error.response.data
                    : "Unknown error occurred";
            setMessage(errorMessage);
        }
    };

    const handleChange = (e) => {
        setForgottenEmail(e.target.value);
    };

    return (
        <aside className="login-overlay">
            <div className="forgot-container">
                <h2 className="forgot-container__header">Forget Or Need To Reset Your Password?</h2>
                <form className="forgot-form" onSubmit={handleSubmit}>
                    <h3 className="forgot-form__header">Enter your email address👇</h3>
                    <input
                        className="forgot-form__input"
                        placeholder="Enter email here"
                        type="email"
                        value={forgottenEmail}
                        onChange={handleChange}
                    />
                    {message && <p className="forgot-status-message">{message}</p>}

                    <button
                        type="submit"
                        className="forgot-form__close-button forgot-form__close-button--send"
                    >
                        Send reset link
                    </button>
                    <p className="forgot-form__body">
                        If you have an email associated with us, we will send you a reset link!
                        Check your inbox
                    </p>
                    <button
                        type="button"
                        className="forgot-form__close-button"
                        onClick={() => {
                            setForgotPassword(false);
                        }}
                    >
                        Exit
                    </button>
                </form>
            </div>
        </aside>
    );
}

export default Forgot;
