import React from "react";
import { useModal } from "../ModalContext/ModalContext";
import Login from "../Login/Login";
import SignUpForm from "../SignUpForm/SignUpForm";

function ModalContainer() {
    const { showLogin, showSignUp } = useModal();

    return (
        <>
            {showLogin && <Login />}
            {showSignUp && <SignUpForm />}
        </>
    );
}

export default ModalContainer;
