import "./Gas.scss";
import React, { useState } from "react";
import { useAuth } from "../../Components/LoginContext/LoginContext";
import Search from "../../Components/Search/Search";
import Hero from "../../Components/Hero/Hero";
import About from "../../Components/About/About";
import Mapbox from "../../Components/Mapbox/Mapbox";
import PriceCards from "../../Components/PriceCards/PriceCards";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Helmet } from "react-helmet-async";

const libraries = ["places", "visualization"];

function Gas() {
    const { isLoggedIn } = useAuth();
    const [showMapModal, setShowMapModal] = useState(false);

    return (
        <>
            <Helmet>
                <title>Gas Stations</title>
                <meta
                    name="description"
                    content="Find tax free gas and get the cheapest gas price near you while also supporting indigenous business. This first nation fuel finder is the best tool to find the best gas prices."
                />
                <link rel="canonical" href="/gas" />
            </Helmet>
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                libraries={["places", "visualization"]}
            >
                <main className="main">
                    <Search setShowMapModal={setShowMapModal} />
                    <Hero />
                    {!isLoggedIn && (
                        <div className="login-false">Login to save favourites here</div>
                    )}
                    <PriceCards />
                    <About />
                    {showMapModal && <Mapbox setShowMapModal={setShowMapModal} />}
                </main>
            </APIProvider>
        </>
    );
}

export default Gas;
