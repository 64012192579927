import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./ContactForm.scss";
import contact from "../../Assets/Icons/contact.svg";
import axios from "axios";
import useIntersectionObserver from "../Hooks/IntersectionObserver";

function ContactForm() {
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [ref1, entries1] = useIntersectionObserver(0.3);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        if (data.middleName) {
            console.log("scram, bot!");
            return;
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/contact/form`,
                {
                    name: data.name,
                    email: data.email,
                    message: data.message,
                }
            );
            setShowSuccess(true);
            reset();
            setTimeout(() => {
                setShowSuccess(false);
            }, 5000);
        } catch (error) {
            setShowFailed(true);

            setTimeout(() => {
                setShowFailed(false);
            }, 3000);

            console.error("Error sending email:", error);
        }
    };

    return (
        <section
            className={`contact-container ${entries1[0]?.isIntersecting ? "visible" : ""}`}
            ref={ref1}
        >
            <div className="contact-container-top">
                <img className="contact-container-top__img" src={contact} alt="contact us" />
                <h1 className="contact-container-top__header">Contact Us</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                <input
                    type="text"
                    name="middleName"
                    className="middle"
                    {...register("middleName")}
                    style={{ display: "none" }}
                />
                <div className="contact-form__section">
                    <label htmlFor="name" className="contact-form__label">
                        Name:
                    </label>
                    <input
                        placeholder="Please enter your name"
                        id="name"
                        {...register("name", { required: true })}
                        className="contact-form__input"
                    />
                    {errors.name && <span className="error-message">Name is required</span>}
                </div>
                <div className="contact-form__section">
                    <label htmlFor="email" className="contact-form__label">
                        Email:
                    </label>
                    <input
                        placeholder="Please enter your email"
                        id="email"
                        {...register("email", { required: true })}
                        className="contact-form__input"
                    />
                    {errors.email && <span className="error-message">Email is required</span>}
                </div>
                <div className="contact-form__section contact-form__section--text">
                    <label htmlFor="message" className="contact-form__label">
                        Message:
                    </label>
                    <textarea
                        placeholder="Please let us know how we can help"
                        rows="4"
                        id="message"
                        {...register("message", { required: true })}
                        className="contact-form__text-area"
                    ></textarea>
                    {errors.message && <span className="error-message">Message is required</span>}
                </div>
                <button type="submit" className="contact-form__button">
                    Send Message
                </button>
            </form>
            <section className="contact-container-text">
                <h3 className="contact-container-text__header">
                    Looking to get a hold of us? Please fill out the form above and we'll get in
                    contact with you as soon as possible!
                </h3>
                <p className="contact-container-text__body">
                    Please let us know if you see an error on the site or if you would like us to
                    add or update some information about your business.
                </p>
            </section>
            {showSuccess && <div className="contact-container__sent">Email sent successfully!</div>}
            {showFailed && (
                <div className="contact-container__failed">
                    Email was not sent! Please try again.
                </div>
            )}
        </section>
    );
}

export default ContactForm;
