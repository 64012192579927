import React, { useRef, useState } from "react";
import "./SignUpForm.scss";
import axios from "axios";
import userLogin from "../../Assets/Icons/user-login.svg";
import userAdd from "../../Assets/Icons/user-add.svg";
import verify from "../../Assets/Icons/verify.svg";
import email from "../../Assets/Icons/email.svg";
import lock from "../../Assets/Icons/lock.svg";
import FormField from "../Hooks/FormField";
import Login from "../Login/Login";
import validateForm from "../Hooks/ValidateLogin";
import { useModal } from "../ModalContext/ModalContext";

function SignUpForm() {
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({});
    const firstNameInputRef = useRef(null);
    const lastNameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const confirmEmailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const confirmPasswordInputRef = useRef(null);

    const [submissionAttempted, setSubmissionAttempted] = useState(false);
    const [view, setView] = useState("login");

    const [loginError, setLoginError] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [message, setMessage] = useState("");

    const { closeSignUp } = useModal();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginError("");
        if (name !== "confirmEmail" && name !== "confirmPassword") {
            setSubmissionAttempted(false);
        }
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmissionAttempted(true);
        setSubmitting(true);
        const validationErrors = validateForm(userInfo, "signup");
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/api/users/register`,
                    {
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        email: userInfo.email,
                        password: userInfo.password,
                        confirmPassword: userInfo.confirmPassword,
                    }
                );
                setSubmitting(false);
                setMessage(
                    "Registration successful! Please check your email to verify your account."
                );

                setTimeout(() => {
                    closeSignUp();
                }, 2000);
            } catch (error) {
                setSubmitting(false);
                const errorMessage = error.response
                    ? error.response.data.message
                    : "Registration failed. Please try again later.";
                setMessage(errorMessage);
                setTimeout(() => {
                    setMessage("");
                }, 4000);
            }
        } else {
            setErrors(validationErrors);
            setSubmitting(false);
        }
    };

    const handleKeyDown = (e, inputName) => {
        if (e.key === "Enter") {
            e.preventDefault();

            if (inputName === "firstName") {
                lastNameInputRef.current.focus();
            } else if (inputName === "lastName") {
                emailInputRef.current.focus();
            } else if (inputName === "email") {
                confirmEmailInputRef.current.focus();
            } else if (inputName === "confirmEmail") {
                passwordInputRef.current.focus();
            } else if (inputName === "password") {
                confirmPasswordInputRef.current.focus();
            } else if (inputName === "confirmPassword") {
                handleSubmit(e);
            }
        }
    };

    return (
        <section className="login-overlay">
            {view === "login" ? (
                <>
                    <div className="login-container-top">
                        <div className="top-left" onClick={closeSignUp}>
                            <img className="top-left__img" src={userLogin} alt="Login" />
                            <p className="top-left__text">Login</p>
                        </div>
                        <div className="top-right" onClick={() => setView("signup")}>
                            <img className="top-right__img" src={userAdd} alt="Sign Up" />
                            <p className="top-right__text">Sign Up</p>
                        </div>
                    </div>
                    <div className="signin-container-bottom">
                        <form className="signup-form" onSubmit={handleSubmit}>
                            <FormField
                                type="text"
                                name="firstName"
                                label="First Name"
                                value={userInfo.firstName}
                                onChange={handleChange}
                                placeholder="What's Your First Name?"
                                error={errors.firstName}
                                inputRef={firstNameInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "firstName")}
                                icon={userAdd}
                                containerClass="signup-form__field"
                                labelClass="signup-form__label"
                                inputClass={`signup-form__input--email ${
                                    (errors.firstName || loginError) && submissionAttempted
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                                iconClass="signup-form__img"
                            />
                            <FormField
                                type="text"
                                name="lastName"
                                label="Last Name"
                                value={userInfo.lastName}
                                onChange={handleChange}
                                placeholder="What's Your Last Name?"
                                error={errors.lastName}
                                inputRef={lastNameInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "lastName")}
                                containerClass="signup-form__field--pad"
                                labelClass="signup-form__label"
                                inputClass={`signup-form__input--email ${
                                    (errors.lastName || loginError) && submissionAttempted
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                            />
                            <FormField
                                type="email"
                                name="email"
                                label="Email"
                                value={userInfo.email}
                                onChange={handleChange}
                                placeholder="What's Your Email?"
                                error={errors.email}
                                inputRef={emailInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "email")}
                                icon={email}
                                containerClass="signup-form__field"
                                labelClass="signup-form__label"
                                inputClass={`signup-form__input--email ${
                                    (errors.email || loginError) && submissionAttempted
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                                iconClass="signup-form__img"
                            />
                            <FormField
                                type="email"
                                name="confirmEmail"
                                label="Confirm Email"
                                value={userInfo.confirmEmail}
                                onChange={handleChange}
                                placeholder="Can You Confirm That?"
                                error={errors.confirmEmail}
                                inputRef={confirmEmailInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "confirmEmail")}
                                containerClass="signup-form__field--pad"
                                labelClass="signup-form__label"
                                inputClass={`signup-form__input--email ${
                                    (errors.confirmEmail || loginError) && submissionAttempted
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                            />
                            <FormField
                                type="password"
                                name="password"
                                label="Password"
                                value={userInfo.password}
                                onChange={handleChange}
                                placeholder="Enter A Password"
                                error={errors.password}
                                inputRef={passwordInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "password")}
                                icon={lock}
                                containerClass="signup-form__field"
                                labelClass="signup-form__label"
                                inputClass={`signup-form__input--password ${
                                    (errors.password || loginError) && submissionAttempted
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message error-message--password"
                                iconClass="signup-form__img"
                            />
                            <FormField
                                type="password"
                                name="confirmPassword"
                                label="Confirm Password"
                                value={userInfo.confirmPassword}
                                onChange={handleChange}
                                placeholder="Can You Confirm That?"
                                error={errors.confirmPassword}
                                inputRef={confirmPasswordInputRef}
                                onKeyDown={(e) => handleKeyDown(e, "confirmPassword")}
                                containerClass="signup-form__field--pad"
                                labelClass="signup-form__label"
                                inputClass={`signup-form__input--email ${
                                    (errors.confirmPassword || loginError) && submissionAttempted
                                        ? "input-error"
                                        : ""
                                }`}
                                errorClass="error-message"
                            />
                            <button
                                className="signup-form__button"
                                type="submit"
                                disabled={submitting}
                            >
                                {submitting ? "Submitting...." : "Sign Up!"}
                                <img
                                    className="signup-form__button--icon"
                                    src={verify}
                                    alt="signup icon"
                                />
                            </button>
                            {message && <p className="register-success-message">{message}</p>}
                        </form>
                        <button className="signup-form__close-button" onClick={closeSignUp}>
                            Close
                        </button>
                    </div>
                </>
            ) : (
                <Login />
            )}
        </section>
    );
}

export default SignUpForm;
