import React from "react";
import "./Pagination.scss";

function Pagination({
    currentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    handleNextPage,
    handlePreviousPage,
}) {
    return (
        <div className="pagination">
            <div className="pagination-upper">
                <button className="pagination-upper__first" onClick={handleFirstPage}>
                    First
                </button>
                <p className="pagination-upper__current">
                    {currentPage} / {totalPages}
                </p>
                <button className="pagination-upper__last" onClick={handleLastPage}>
                    Last
                </button>
            </div>

            <div className="pages">
                {currentPage > 1 && (
                    <button className="pages__previous" onClick={handlePreviousPage}>
                        Previous
                    </button>
                )}
                {currentPage < totalPages && (
                    <button className="pages__next" onClick={handleNextPage}>
                        Next
                    </button>
                )}
            </div>
        </div>
    );
}
export default Pagination;
