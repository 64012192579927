import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./Sidebar.scss";
import { Link } from "react-router-dom";

export default () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleStateChange = (state) => {
        setIsOpen(state.isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <Menu right isOpen={isOpen} onStateChange={handleStateChange}>
            <Link id="home" className="menu-item" to="/" onClick={closeMenu}>
                Home
            </Link>
            <Link id="about" className="menu-item" to="/gas" onClick={closeMenu}>
                Gas Stations
            </Link>
            <Link
                id="contact"
                className="menu-item"
                to="/indigenous-businesses"
                onClick={closeMenu}
            >
                Businesses
            </Link>
            <Link id="contact" className="menu-item" to="/contact-us" onClick={closeMenu}>
                Report a problem / Request a change
            </Link>
        </Menu>
    );
};
