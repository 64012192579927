import React, { useState } from "react";
import "./ReportPrice.scss";
import axios from "axios";

function ReportPrice({ marker, exit }) {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [price, setPrice] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const priceRegex = /^\d+\.\d{2}$/;
        if (!priceRegex.test(price)) {
            setErrorMessage("Price format should be x.xx");
            return;
        }
        {
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/markers/price`,
                    {
                        ID: marker.ID,
                        Price: price,
                    }
                );
                setPrice("");
                setShowSuccessMessage(true);

                setTimeout(() => {
                    setShowSuccessMessage(false);
                    exit();
                }, 2000);
            } catch (error) {
                console.error("Error updating price:", error.message);
            }
        }
    };

    return (
        <article className="report-price-container">
            {showSuccessMessage && (
                <div className="price-update-success">Thanks for contributing!</div>
            )}
            <form className="report-price-form" onSubmit={handleSubmit}>
                <p className="report-price-form__header">Current Price:</p>
                <div className="report-price-form__container">
                    <p className="report-price-form__dollar">$</p>
                    <input
                        placeholder="ex. 1.55"
                        className="report-price-form__input"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <button className="report-price-form__submit" onClick={() => setTimeout}>
                    Submit
                </button>
            </form>
        </article>
    );
}
export default ReportPrice;
