import { useState, useRef } from "react";

const useIntersectionObserver = (threshold = 0.1) => {
    const [entries, setEntries] = useState([]);
    const observer = useRef(null);

    const refCallback = (node) => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(
            (newEntries) => {
                setEntries(
                    newEntries.map((entry) => ({
                        isIntersecting: entry.isIntersecting,
                        target: entry.target,
                    }))
                );
            },
            { threshold }
        );

        if (node) observer.current.observe(node);
    };

    return [refCallback, entries];
};

export default useIntersectionObserver;
