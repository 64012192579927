import "./PriceCards.scss";
import React, { useEffect, useState } from "react";
import Pin from "../../Assets/Icons/pin.svg";
import { useAuth } from "../LoginContext/LoginContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function PriceCards() {
    const [stations, setStations] = useState([]);
    const { isLoggedIn } = useAuth();
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchStations = async () => {
            try {
                let response;
                if (isLoggedIn && token) {
                    const { userId } = jwtDecode(token);
                    response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}/favourites`,
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );
                } else {
                    response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/markers/random`
                    );
                }
                setStations(response.data);
            } catch (error) {
                console.error("Failed to fetch stations", error);
            }
        };

        fetchStations();
    }, [isLoggedIn, token]);

    return (
        <section className="price-card-container">
            {stations.map((station, index) => {
                const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${station.Location.Lat},${station.Location.Long}`;

                return (
                    <div key={index} className="price-card">
                        <div className="price-card-container__buffer">
                            <h3 className="price-card-container__header">{station.Name}</h3>
                        </div>
                        <p className="price-card-container__price">${station.Price}/L</p>
                        <p className="price-card-container__location">
                            {station.Address}, {station.City}, {station.Province}
                        </p>
                        <div className="price-card-container__group">
                            <a href={directionsUrl} target="_blank" rel="noopener noreferrer">
                                <p className="price-card-container__directions">Directions</p>
                                <img className="price-card-container__pin" src={Pin} alt="pin" />
                            </a>
                        </div>
                    </div>
                );
            })}
        </section>
    );
}

export default PriceCards;
