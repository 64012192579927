import "./Hero.scss";
import React from "react";

function Hero() {
    return (
        <section className="hero-container">
            <img
                src="https://res.cloudinary.com/dcydlrusb/image/upload/f_auto,q_auto/o6lyjsqp2xoxetiz44ab"
                alt="Map of Canada"
                className="hero-container__map"
            />
            <img
                src="https://res.cloudinary.com/dcydlrusb/image/upload/v1721874404/koldbmcdrgd7feeuq0qm.svg"
                alt="road trip car going to gas station"
                className="hero-container__roadie"
            />
        </section>
    );
}

export default Hero;
