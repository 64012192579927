import React from "react";
import "./HomeAbout.scss";
import { Link } from "react-router-dom";
import useIntersectionObserver from "../Hooks/IntersectionObserver";

function HomeAbout() {
    const [ref1, entries1] = useIntersectionObserver(0.3);
    const [ref2, entries2] = useIntersectionObserver(0.3);
    const [ref3, entries3] = useIntersectionObserver(0.3);

    return (
        <section className="home-about-container">
            <div
                className={`home-about-card ${
                    entries1.some((entry) => entry.isIntersecting) ? "visible" : ""
                }`}
                ref={ref1}
            >
                <h2 className="home-about-card__header">
                    Welcome to RezBuddy, your ultimate navigator to First Nation-owned gas stations
                    and businesses across Canada!
                </h2>
                <p className="home-about-card__body">
                    Whether you're looking to save on tax-free fuel with your Indian Status or eager
                    to support indigenous enterprises, RezBuddy makes it seamless with a
                    user-friendly map, dotted with gas stations just for you. Dive into a world of
                    convenience where you can easily find, save, and get directions to the nearest
                    First Nation gas stations near you. Search manually or use your current
                    location, either way, RezBuddy ensures you're never far from a great deal or a
                    supporting community.
                </p>
            </div>
            <div
                className={`home-about-card ${
                    entries2.some((entry) => entry.isIntersecting) ? "visible" : ""
                }`}
                ref={ref2}
            >
                <h2 className="home-about-card__header">
                    Beyond fuel, RezBuddy is a vibrant marketplace for discovering First Nation
                    businesses in sectors like retail, food, services, and tourism.
                </h2>
                <p className="home-about-card__body">
                    Whether you're searching for unique local crafts, delicious indigenous cuisine,
                    or reliable services, our extensive directory is tailored to enhance your access
                    and engagement. Use our quick search feature to explore new favorites and
                    connect with the heart of Canada's indigenous communities. Our First Nation
                    business directory, lists all government-registered First Nation businesses, and
                    we are accepting any first nation business to list in our directory for
                    <span className="bold"> FREE</span>. Please reachout to us via our
                    <Link to="/contact-us" className="home-about-card__link">
                        Contact Us
                    </Link>
                    page if you're interested.
                </p>
                <p className="home-about-card__body">
                    With RezBuddy, every transaction is a step towards empowering First Nation
                    businesses and cultivating stronger, sustainable communities.
                </p>
            </div>
            <div
                className={`home-about-card ${
                    entries3.some((entry) => entry.isIntersecting) ? "visible" : ""
                }`}
                ref={ref3}
            >
                <h2 className="home-about-card__header">Connect With Us</h2>
                <p className="about-container__body">
                    <strong>RezBuddy </strong>is more than just a search tool; it's a bridge between
                    cultures, economies, and individuals striving for a better, more connected
                    Canada. If you encounter any discrepancies or wish to learn more about how we
                    can support your community,
                    <Link to="/contact-us" className="home-about-card__link">
                        Contact Us.
                    </Link>
                    Together, let's drive towards a future where every fuel purchase supports
                    community growth and affordable living.
                </p>
            </div>
            <div className="about-container__div">
                <p className="about-container__bullet-title">Disclaimer</p>
                <p className="about-container__sub-bod">
                    We strive to provide the most accurate and up-to-date information on First
                    Nation business locations and services across Canada, including gas stations and
                    fuel prices. Our data is gathered from a collaborative effort that includes the
                    Government of Canada’s First Nation Business Directory and contributions from
                    our valued users and community members. However, due to the dynamic nature of
                    business operations and fuel prices, there may be instances where the
                    information provided does not reflect the current situation. We encourage our
                    users to help enhance the accuracy and completeness of our data. If you
                    encounter any discrepancies, outdated information, or missing locations—whether
                    they are gas stations or other First Nation businesses—please do not hesitate to
                    <Link to="/contact-us" className="about-container__link">
                        contact us
                    </Link>
                    . Your feedback is invaluable in helping us make the RezBuddy is as reliable and
                    useful as possible.
                </p>
            </div>
        </section>
    );
}

export default HomeAbout;
