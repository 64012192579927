import "./Search.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "../LocationContext/LocationContext";
import Loading from "../Loading/Loading";
import { APIProvider } from "@vis.gl/react-google-maps";

function Search({ setShowMapModal }) {
    const { setSelectedLocation } = useLocation();
    const locationInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isMapsLoaded, setIsMapsLoaded] = useState(false);

    useEffect(() => {
        if (!isMapsLoaded || !window.google) return;

        const autocomplete = new window.google.maps.places.Autocomplete(locationInputRef.current, {
            types: ["(cities)"],
            componentRestrictions: { country: "CA" },
        });
        autocomplete.setFields(["geometry"]);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setSelectedLocation({ lat, lng });
                setShowMapModal(true);
            } else {
                console.error("No details available for input: '" + place.name + "'");
            }
        });
    }, [isMapsLoaded, setSelectedLocation, setShowMapModal]);

    const handleFindGasNearMe = useCallback(
        (e) => {
            e.preventDefault();
            setIsLoading(true);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setSelectedLocation({ lat: latitude, lng: longitude });
                        setShowMapModal(true);
                        setIsLoading(false);
                    },
                    (error) => {
                        console.error("Geolocation Error: ", error);
                        setIsLoading(false);
                        alert("Unable to retrieve your location.");
                    }
                );
            } else {
                setIsLoading(false);
                alert("Geolocation is not supported by this browser.");
            }
        },
        [setSelectedLocation, setShowMapModal]
    );

    return (
        <section className="search-container">
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                libraries={["places"]}
                onLoad={() => setIsMapsLoaded(true)}
            >
                <form className="search-form">
                    <button
                        type="button"
                        className="search-form__header"
                        onClick={handleFindGasNearMe}
                    >
                        Find Gas Near Me
                    </button>
                    <label className="search-form__label">
                        <input
                            ref={locationInputRef}
                            className="search-form__input"
                            type="text"
                            placeholder="Enter city or area here..."
                            disabled={!isMapsLoaded}
                        />
                    </label>
                    {isLoading && <Loading />}
                </form>
            </APIProvider>
        </section>
    );
}

export default Search;
