const validateForm = (values, formType) => {
    let errors = {};

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email is not valid";
    }

    if (!values.password) {
        errors.password = "Password is required";
    } else if (values.password.length < 8) {
        errors.password = "Password must be at least 8 characters long";
    }

    if (formType === "signup") {
        if (!values.firstName || values.firstName.length < 3) {
            errors.firstName = "First name must be at least 3 characters long";
        }

        if (!values.lastName || values.lastName.length < 3) {
            errors.lastName = "Last name must be at least 3 characters long";
        }

        if (values.email !== values.confirmEmail) {
            errors.confirmEmail = "Emails do not match";
        }

        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }
    }

    return errors;
};

export default validateForm;
