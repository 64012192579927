import React from "react";

function FormField({
    type,
    name,
    label,
    value,
    onChange,
    placeholder,
    error,
    inputRef,
    onKeyDown,
    icon,
    containerClass,
    labelClass,
    inputClass,
    iconClass,
}) {
    return (
        <div className={containerClass}>
            {icon && <img className={iconClass} src={icon} alt={`${name} icon`} />}
            <label htmlFor={name} className={labelClass}>
                {label}
            </label>
            <input
                type={type}
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                ref={inputRef}
                onKeyDown={onKeyDown}
                className={inputClass}
            />
            {error && <div className="error-message">{error}</div>}
        </div>
    );
}

export default FormField;
