import React from "react";
import UpdateAddFormGroup from "../UpdateAddFormGroup/UpdateAddFormGroup";

function UpdateAddForm({
    mode,
    businessType,
    addType,
    selectedBusiness,
    setSelectedBusiness,
    onSubmit,
    onDelete,
}) {
    const isIndigenousBusiness =
        (mode === "update" && businessType === "indigenous_business") ||
        (mode === "add" && addType === "indigenous_business");
    const isGasStation =
        (mode === "update" && businessType === "gas_station") ||
        (mode === "add" && addType === "gas_station");

    const provincePlaceholder = isIndigenousBusiness ? "ex. Ontario" : "ex. ON";

    return (
        <form className="form" onSubmit={onSubmit}>
            <h2 className="form__title">
                {mode === "add" ? "Add" : "Update"} Business Information
            </h2>

            {isIndigenousBusiness && (
                <>
                    <UpdateAddFormGroup
                        id="companyOperatingName"
                        label="Company Operating Name"
                        placeholder="Company Operating Name"
                        value={selectedBusiness.companyOperatingName || ""}
                        onChange={(e) =>
                            setSelectedBusiness({
                                ...selectedBusiness,
                                companyOperatingName: e.target.value,
                            })
                        }
                    />
                    <UpdateAddFormGroup
                        id="companyLegalName"
                        label="Company Legal Name"
                        placeholder="Company Legal Name"
                        value={selectedBusiness.companyLegalName || ""}
                        onChange={(e) =>
                            setSelectedBusiness({
                                ...selectedBusiness,
                                companyLegalName: e.target.value,
                            })
                        }
                    />
                    <UpdateAddFormGroup
                        id="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        value={selectedBusiness.email || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, email: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="phoneNumber"
                        label="Phone Number"
                        placeholder="ex. xxx-xxx-xxxx"
                        value={selectedBusiness.phoneNumber || ""}
                        onChange={(e) =>
                            setSelectedBusiness({
                                ...selectedBusiness,
                                phoneNumber: e.target.value,
                            })
                        }
                    />
                    <UpdateAddFormGroup
                        id="url"
                        label="URL"
                        placeholder="https://www.example.ca"
                        type="url"
                        value={selectedBusiness.url || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, url: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="city"
                        label="City"
                        placeholder="City"
                        value={selectedBusiness.city || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, city: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="province"
                        label="Province"
                        placeholder={provincePlaceholder}
                        value={selectedBusiness.province || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, province: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="address1"
                        label="Address 1"
                        placeholder="Address"
                        value={selectedBusiness.address1 || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, address1: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="address2"
                        label="Address 2"
                        placeholder="P.O Box or Apt #"
                        value={selectedBusiness.address2 || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, address2: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="postalCode"
                        label="Postal Code"
                        placeholder="ex. xxx xxx"
                        value={selectedBusiness.postalCode || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, postalCode: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="companyDescription"
                        label="Company Description"
                        placeholder="Company Description"
                        type="textarea"
                        value={selectedBusiness.companyDescription || ""}
                        onChange={(e) =>
                            setSelectedBusiness({
                                ...selectedBusiness,
                                companyDescription: e.target.value,
                            })
                        }
                    />
                    <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <select
                            name="category"
                            className="form__select"
                            id="category"
                            value={selectedBusiness.category || ""}
                            onChange={(e) =>
                                setSelectedBusiness({
                                    ...selectedBusiness,
                                    category: e.target.value,
                                })
                            }
                        >
                            <option value="" disabled>
                                Select Category
                            </option>
                            <option value="Retail">Retail</option>
                            <option value="Food">Food</option>
                            <option value="Services">Services</option>
                            <option value="Tourism">Tourism</option>
                        </select>
                    </div>
                    <div className="form-group form-group--featured">
                        <label htmlFor="featured">Featured</label>
                        <input
                            type="checkbox"
                            name="featured"
                            className="form__checkbox"
                            id="featured"
                            checked={selectedBusiness.featured || false}
                            onChange={(e) =>
                                setSelectedBusiness({
                                    ...selectedBusiness,
                                    featured: e.target.checked,
                                })
                            }
                        />
                    </div>
                </>
            )}

            {isGasStation && (
                <>
                    <UpdateAddFormGroup
                        id="name"
                        label="Name"
                        placeholder="Company Operating Name"
                        value={selectedBusiness.name || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, name: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="address"
                        label="Address"
                        placeholder="Address"
                        value={selectedBusiness.address || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, address: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="city"
                        label="City"
                        placeholder="City"
                        value={selectedBusiness.city || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, city: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="province"
                        label="Province"
                        placeholder={provincePlaceholder}
                        value={selectedBusiness.province || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, province: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="phone"
                        label="Phone"
                        placeholder="ex. xxx-xxx-xxxx"
                        value={selectedBusiness.phone || ""}
                        onChange={(e) =>
                            setSelectedBusiness({ ...selectedBusiness, phone: e.target.value })
                        }
                    />
                    <UpdateAddFormGroup
                        id="lat"
                        label="Latitude"
                        placeholder="ex. 49.3116147"
                        type="number"
                        value={selectedBusiness.location?.lat || ""}
                        onChange={(e) =>
                            setSelectedBusiness({
                                ...selectedBusiness,
                                location: {
                                    ...selectedBusiness.location,
                                    lat: e.target.value,
                                },
                            })
                        }
                    />
                    <UpdateAddFormGroup
                        id="long"
                        label="Longitude"
                        placeholder="ex. -124.9111711"
                        type="number"
                        value={selectedBusiness.location?.long || ""}
                        onChange={(e) =>
                            setSelectedBusiness({
                                ...selectedBusiness,
                                location: {
                                    ...selectedBusiness.location,
                                    long: e.target.value,
                                },
                            })
                        }
                    />
                </>
            )}

            <button type="submit" className="form__button">
                {mode === "add" ? "Add Business" : "Update Business"}
            </button>
            {mode === "update" && (
                <button
                    type="button"
                    className="form__button form__button--delete"
                    onClick={onDelete}
                >
                    Delete Business
                </button>
            )}
        </form>
    );
}

export default UpdateAddForm;
