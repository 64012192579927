import React, { useEffect, useState } from "react";
import "./Mapbox.scss";
import { useLocation } from "../LocationContext/LocationContext";
import axios from "axios";
import PinModal from "../PinModal/PinModal";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";

const defaultCenter = { lat: 44.608246, lng: -79.419678 };

function Mapbox({ setShowMapModal }) {
    const { selectedLocation } = useLocation();
    const center = selectedLocation.lat && selectedLocation.lng ? selectedLocation : defaultCenter;

    const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);

    useEffect(() => {
        fetchMarkersData();
    }, []);

    const fetchMarkersData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/markers?apiKey=${process.env.REACT_APP_BACKEND_API}`
            );
            setMarkers(response.data);
        } catch (error) {
            console.error("Error fetching markers data:", error);
        }
    };

    const handleMarkerClick = (marker) => {
        setActiveMarker(marker);
    };

    const exit = () => {
        setActiveMarker(null);
    };

    return (
        <section className="mapbox-container">
            <button
                onClick={() => setShowMapModal(false)}
                className="close-map"
                aria-label="Close map"
            >
                <p className="close-map__x">X</p>
            </button>

            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={["places"]}>
                <div style={{ height: "100%", width: "100%" }}>
                    <Map
                        defaultZoom={10}
                        defaultCenter={center}
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
                        gestureHandling={"cooperative"}
                    >
                        {markers.map((marker, index) => (
                            <AdvancedMarker
                                key={index}
                                position={{
                                    lat: parseFloat(marker.Location?.Lat) || 0,
                                    lng: parseFloat(marker.Location?.Long) || 0,
                                }}
                                onClick={() => handleMarkerClick(marker)}
                            />
                        ))}
                    </Map>
                </div>
            </APIProvider>

            {activeMarker && (
                <PinModal exit={exit} marker={activeMarker} onClose={() => setActiveMarker(null)} />
            )}
        </section>
    );
}

export default Mapbox;
