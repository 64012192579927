import React from "react";
import { Link } from "react-router-dom";
import "../Privacy/Privacy.scss";
import { Helmet } from "react-helmet-async";

function Tos() {
    return (
        <>
            <Helmet>
                <title>Terms of Service</title>
                <meta
                    name="description"
                    content="This page outlines RezBuddy's terms of service."
                />
                <link rel="canonical" href="/privacy-policy" />
            </Helmet>
            <main className="privacy main">
                <h1 className="privacy__header">Terms of Service</h1>
                <p className="privacy__body">
                    Welcome to RezBuddy ("we," "us," or "our"). These Terms of Service ("Terms")
                    govern your use of our website ("Site") and mobile application ("App"). By
                    accessing or using our services, you agree to be bound by these Terms. If you do
                    not agree to these Terms, you may not use our services.
                </p>

                <h2 className="privacy__subhead">1. Acceptance of Terms</h2>
                <p className="privacy__body">
                    By using our Site and App, you agree to comply with and be bound by these Terms
                    and all applicable laws and regulations. If you do not agree to these Terms, you
                    are not authorized to use our services.
                </p>

                <h2 className="privacy__subhead">2. Use of Our Services</h2>
                <h3 className="privacy__subheadxs">2.1 Eligibility</h3>
                <p className="privacy__body">
                    To use our services, you must be at least 13 years old and have the legal
                    capacity to enter into these Terms. If you are under 18, you must have the
                    permission of a parent or guardian to use our services.
                </p>

                <h3 className="privacy__subheadxs">2.2 Account Registration</h3>
                <p className="privacy__body">
                    To access certain features of our services, you may need to create an account.
                    You agree to provide accurate, current, and complete information during the
                    registration process and to update such information as necessary to keep it
                    accurate, current, and complete. You are responsible for safeguarding your
                    account credentials and for any activities or actions under your account.
                </p>

                <h3 className="privacy__subheadxs">2.3 Prohibited Activities</h3>
                <p className="privacy__body">When using our services, you agree not to:</p>
                <ul className="privacy__list">
                    <li className="privacy__item">Violate any applicable laws or regulations</li>
                    <li className="privacy__item">
                        Infringe the rights of others, including intellectual property rights
                    </li>
                    <li className="privacy__item">
                        Use our services for any unlawful, harmful, or fraudulent purposes
                    </li>
                    <li className="privacy__item">
                        Distribute spam, viruses, or other harmful software
                    </li>
                    <li className="privacy__item">
                        Interfere with or disrupt the integrity or performance of our services
                    </li>
                    <li className="privacy__item">
                        Attempt to gain unauthorized access to our systems or networks
                    </li>
                    <li className="privacy__item">
                        Impersonate any person or entity or misrepresent your affiliation with any
                        person or entity
                    </li>
                </ul>

                <h2 className="privacy__subhead">3. Content</h2>
                <h3 className="privacy__subheadxs">3.1 Your Content</h3>
                <p className="privacy__body">
                    You retain ownership of any content you submit, post, or display on or through
                    our services ("Your Content"). By submitting Your Content, you grant us a
                    worldwide, non-exclusive, royalty-free, sublicensable, and transferable license
                    to use, reproduce, distribute, prepare derivative works of, and display Your
                    Content in connection with our services.
                </p>

                <h3 className="privacy__subheadxs">3.2 Our Content</h3>
                <p className="privacy__body">
                    All content provided by us, including text, graphics, logos, images, and
                    software, is our property or the property of our licensors and is protected by
                    copyright, trademark, and other intellectual property laws. You may not use,
                    reproduce, distribute, or create derivative works of Our Content without our
                    express written permission.
                </p>

                <h2 className="privacy__subhead">4. Privacy</h2>
                <p className="privacy__body">
                    Your use of our services is also governed by our Privacy Policy, which explains
                    how we collect, use, and disclose your information. By using our services, you
                    consent to our collection and use of your information as described in the
                    Privacy Policy.
                </p>

                <h2 className="privacy__subhead">5. Disclaimers and Limitation of Liability</h2>
                <h3 className="privacy__subheadxs">5.1 Disclaimers</h3>
                <p className="privacy__body">
                    Our services are provided on an "as is" and "as available" basis. We make no
                    warranties, express or implied, regarding the operation or availability of our
                    services or the accuracy, completeness, or reliability of any content available
                    through our services. To the fullest extent permitted by law, we disclaim all
                    warranties, express or implied, including warranties of merchantability, fitness
                    for a particular purpose, and non-infringement.
                </p>

                <h3 className="privacy__subheadxs">5.2 Limitation of Liability</h3>
                <p className="privacy__body">
                    In no event shall we be liable for any indirect, incidental, special,
                    consequential, or punitive damages, or any loss of profits or revenues, whether
                    incurred directly or indirectly, or any loss of data, use, goodwill, or other
                    intangible losses, resulting from (a) your use or inability to use our services;
                    (b) any unauthorized access to or use of our servers and/or any personal
                    information stored therein; (c) any interruption or cessation of transmission to
                    or from our services; (d) any bugs, viruses, trojans, or the like that may be
                    transmitted to or through our services by any third party; (e) any errors or
                    omissions in any content; or (f) any other matter relating to our services,
                    whether based on warranty, contract, tort (including negligence), or any other
                    legal theory, whether or not we have been informed of the possibility of such
                    damage.
                </p>

                <h2 className="privacy__subhead">6. Indemnification</h2>
                <p className="privacy__body">
                    You agree to indemnify, defend, and hold harmless RezBuddy, its officers,
                    directors, employees, agents, and affiliates from and against any and all
                    claims, liabilities, damages, losses, and expenses, including reasonable
                    attorneys' fees and costs, arising out of or in any way connected with (a) your
                    access to or use of our services; (b) your violation of these Terms; (c) your
                    violation of any third-party right, including any intellectual property right or
                    privacy right; or (d) any claim that Your Content caused damage to a third
                    party.
                </p>

                <h2 className="privacy__subhead">7. Termination</h2>
                <p className="privacy__body">
                    We may terminate or suspend your access to our services, at our sole discretion,
                    at any time and without notice, for conduct that we believe violates these Terms
                    or is harmful to other users of our services, us, or third parties, or for any
                    other reason.
                </p>

                <h2 className="privacy__subhead">8. Governing Law and Dispute Resolution</h2>
                <p className="privacy__body">
                    These Terms shall be governed by and construed in accordance with the laws of
                    the Ontario, Canada, without regard to its conflict of law principles. Any
                    disputes arising out of or in connection with these Terms shall be resolved
                    exclusively through binding arbitration in Orillia, Ontario, except that either
                    party may seek injunctive or other equitable relief in any court of competent
                    jurisdiction.
                </p>

                <h2 className="privacy__subhead">9. Changes to These Terms</h2>
                <p className="privacy__body">
                    We may update these Terms from time to time. We will notify you of any changes
                    by posting the new Terms on this page and updating the "Last Updated" date. You
                    are advised to review these Terms periodically for any changes. Your continued
                    use of our services after any changes to these Terms constitutes your acceptance
                    of the new Terms.
                </p>

                <h2 className="privacy__subhead">10. Contact Us</h2>
                <p className="privacy__body">
                    If you have any questions about these Terms, please{" "}
                    <Link className="privacy__link" to="/contact-us">
                        <p className="privacy__link">Contact Us</p>
                    </Link>
                </p>

                <p className="privacy__body">
                    By using our Site and App, you acknowledge that you have read and understood
                    these Terms of Service.
                </p>
                <p className="privacy__updated">Last Updated: June 19, 2024</p>
            </main>
        </>
    );
}

export default Tos;
