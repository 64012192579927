import React from "react";
import ContactForm from "../../Components/ContactForm/ContactForm";
import "./Contact.scss";
import { Helmet } from "react-helmet-async";

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta
                    name="description"
                    content="Contact us to ask questions, learn more, or request a change for a listed business."
                />
                <link rel="canonical" href="/contact-us" />
            </Helmet>
            <main className="main main--contact">
                <ContactForm />
            </main>
        </>
    );
}

export default Contact;
