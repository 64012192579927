import React from "react";
import "./Footer.scss";
import insta from "../../Assets/Icons/instagram.svg";
import facebook from "../../Assets/Icons/facebook.svg";
import email from "../../Assets/Icons/emailFooter.svg";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer>
            <div className="footer-container">
                <h2 className="footer-container__title">RezBuddy.ca</h2>
                <div className="footer-container__leftright">
                    <nav className="leftright">
                        <ul>
                            <li>
                                <Link to="/" className="leftright__link">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact-us" className="leftright__link">
                                    Contact
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy" className="leftright__link">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/terms-of-service" className="leftright__link--nowrap">
                                    Terms of Service
                                </Link>
                            </li>
                        </ul>
                        <p className="leftright__copyright">Created by:</p>
                        <p className="leftright__copyright--small">© 2024 Jordan Dooley</p>
                    </nav>
                    <div className="footer-social">
                        <h2 className="footer-social__title">Let's Stay Connected! </h2>
                        <div className="footer-social__icon-container">
                            <a
                                href="https://instagram.com/rezbuddy.ca"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Follow us on Instagram!"
                            >
                                <img className="footer-social__icon" src={insta} alt="instagram" />
                            </a>
                            <a
                                href="https://www.facebook.com/profile.php?id=61557975502019"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Follow us on Facebook!"
                            >
                                <img
                                    className="footer-social__icon"
                                    src={facebook}
                                    alt="facebook"
                                />
                            </a>
                            <a
                                href="https://rezbuddy.ca/contact-us"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Contact us here!"
                            >
                                <img className="footer-social__icon" src={email} alt="contact us" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
