import React from "react";
import "./BusinessList.scss";

function BusinessList({ businesses, isLoading }) {
    // console.log("list component", businesses);
    if (isLoading) return <p>Loading...</p>;

    if (businesses.length === 0)
        return <p className="bp-display-container__no-results">Sorry, no results found.</p>;

    return (
        <>
            {businesses.map((business) => (
                <article key={business.id} className="business-card visible">
                    <h3 className="business-card__name">Business Name:</h3>
                    <p className="business-card__body">{business.company_operating_name}</p>
                    <h3 className="business-card__location">Business Location:</h3>
                    <p className="business-card__body">
                        {business.city}, {business.province}
                    </p>
                    <h3 className="business-card__description">Business Description:</h3>
                    <p className="business-card__body">{business.company_description}</p>
                    <div>
                        {business.url ? (
                            <a href={business.url} target="_blank" rel="noopener noreferrer">
                                <p className="business-card__link">Website Link</p>
                            </a>
                        ) : (
                            <p className="business-card__error">No website available</p>
                        )}
                    </div>
                </article>
            ))}
        </>
    );
}

export default BusinessList;
